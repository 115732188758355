const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
 

exports.phoneNumberEqual = function(phoneNumber1, phoneNumber2, defaultCountryID){
    const number1 = phoneUtil.parseAndKeepRawInput(phoneNumber1, defaultCountryID);
    const number2 = phoneUtil.parseAndKeepRawInput(phoneNumber2, defaultCountryID);

    return number1.getCountryCode() + '' + number1.getNationalNumber() == 
        number2.getCountryCode() + '' + number2.getNationalNumber();
}

exports.toIntNumber = function(phoneNumber, defaultCountryID){
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, defaultCountryID);
    return '+' + number.getCountryCode() + '' + number.getNationalNumber();
}

exports.toWaboxAppNumber = function(phoneNumber, defaultCountryID){
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, defaultCountryID);
    return number.getCountryCode() + '' + number.getNationalNumber();
}

exports.toNationalAppNumber = function(phoneNumber, defaultCountryID){
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, defaultCountryID);
    return number.getNationalNumber();
}