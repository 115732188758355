<template>
    <b-alert :show="!$helpers.isBlank(message)" showTop class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 10000;" :variant="message.type" dismissible>
        <span v-html="message.html"></span>
    </b-alert>
</template>

<script>
    export default {
        props: ['message']
    }
</script>